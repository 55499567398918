import React from 'react'
import Testimonial from '../components/sections/Testimonial';

const Team = () => {
    return ( 
        <Testimonial className="illustration-section-01"/>

     );
}
 
export default Team;